type FetchOptions = RequestInit & {
    // withCredentials?: boolean; // 添加额外的属性    
};
const getEnv = () => {
    let _env = 'dev';
    if (/doomn\.com\.cn/.test(location.host)) {
        _env = 'alpha';
    } else if (/doomn\.com/.test(location.host)) {
        _env = 'prod';
    } else {
        _env = 'dev';
    }
    return _env
}

export const APP_ENV = getEnv()

let BASE_URL = import.meta.env.VITE_API_ENDPOINT;
let LOGIN_URL = import.meta.env.VITE_AUTH_ENDPOINT;
if (APP_ENV == 'prod'){
    BASE_URL = 'https://auth.doomn.com/api/'
    LOGIN_URL = 'https://auth.doomn.com/login'
} else if (APP_ENV == 'alpha') {
    BASE_URL = 'https://auth.doomn.com.cn/api/'
    LOGIN_URL = 'https://auth.doomn.com.cn/login'
} else {
    BASE_URL = 'http://localhost:3000/api/'
    LOGIN_URL = 'http://localhost:3000/login'
}


// const LOGIN_URL = 'http://localhost:3000/login';

// 获取 Access Token（可以从 Cookie、LocalStorage、SessionStorage 等中获取）
const getAccessToken = (): string | null => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const [key, value] = cookie.trim().split('=');
        if (key === 'access_token') {
            return decodeURIComponent(value);
        }
    }
    redirectToLogin();
    return null
};

const checkLogin = () => {
    return getAccessToken() !== null
}

// 跳转到登录页面
const redirectToLogin = () => {
    // 获取当前页面地址
  const currentUrl = window.location.href;
  // 登录页面 URL，附加重定向地址
  const redirectUrl = `${LOGIN_URL}?redirect_uri=${encodeURIComponent(currentUrl)}`;
  // 跳转到登录页面
  window.location.href = redirectUrl;
};

const DEFAULT_HEADERS: Record<string, string> = {
    'credentials': 'include',
    'Content-Type': 'application/json',
};

// 封装 fetch 请求
const fetchAPI = async (url: string, options: FetchOptions = {}): Promise<any> => {
    // const token = getAccessToken();

    try {
        const response = await fetch(`${BASE_URL}${url}`, {
        ...DEFAULT_HEADERS,
        ...options,
        });

        // 如果未授权或禁止访问，跳转到登录页面
        if (response.status === 401 || response.status === 403) {
        redirectToLogin();
        return Promise.reject(new Error('Unauthorized: Redirecting to login'));
        }

        // 解析 JSON 响应
        if (response.ok) {
        return response.json();
        }

        // 其他错误状态处理
        const errorText = await response.text();
        return Promise.reject(new Error(`Error ${response.status}: ${errorText}`));
    } catch (error) {
        console.error('Fetch Error:', error);
        return Promise.reject(error);
    }
};

type FetchParams = {
    url: string;
    data?: any; // 用于 POST/PUT 请求的数据
    params?: Record<string, any>; // 用于 GET/DELETE 请求的查询参数
    options?: RequestInit; // 其他配置项
  };
  
// 格式化查询参数为 URL
const formatQueryParams = (params?: Record<string, any>): string => {
    if (!params) return '';
    const query = new URLSearchParams(params).toString();
    return query ? `?${query}` : '';
};

// GET 请求
const fetchGet = async <T = any>({ url, params, options }: FetchParams): Promise<T> => {
    const query = formatQueryParams(params);
    return fetchAPI(`${url}${query}`, {
        method: 'GET',
        ...options,
    });
};

// POST 请求
const fetchPost = async <T = any>({ url, data, options }: FetchParams): Promise<T> => {
    return fetchAPI(url, {
        method: 'POST',
        body: data,
        ...options,
    });
};

// PUT 请求
const fetchPut = async <T = any>({ url, data, options }: FetchParams): Promise<T> => {
    return fetchAPI(url, {
        method: 'PUT',
        body: JSON.stringify(data),
        ...options,
    });
};

// DELETE 请求
const fetchDel = async <T = any>({ url, params, options }: FetchParams): Promise<T> => {
    const query = formatQueryParams(params);
    return fetchAPI(`${url}${query}`, {
        method: 'DELETE',
        ...options,
    });
};

export {fetchAPI, checkLogin, fetchGet, fetchDel, fetchPost, fetchPut};  