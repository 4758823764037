import OSS from 'ali-oss';
import type { FileId } from '../../packages/excalidraw/element/types';
import type { BinaryFileData, BinaryFileMetadata, DataURL } from '../../packages/excalidraw/types';
import { decompressData } from '../../packages/excalidraw/data/encode';
import { MIME_TYPES } from '../../packages/utils';
import { fetchGet } from './api';

interface SaveFilesParams {
  prefix: string;
  files: { id: string; buffer: Uint8Array }[];
}

async function getOssCredentials() {
  // 远程调用 STS 服务接口获取临时访问凭证
  // const response = await fetchAPI(`api/common/oss/sts`);
  const data = await fetchGet({url: `common/oss/sts`});
  return {
    accessKeyId: data.accessKeyId,
    accessKeySecret: data.accessKeySecret,
    stsToken: data.securityToken,
    bucket: data.bucket,
    endpoint: data.endpoint
  };
}

export const saveFilesToOss = async ({ prefix, files }: SaveFilesParams) => {
  const { accessKeyId, accessKeySecret, stsToken, endpoint, bucket } = await getOssCredentials();

  // 初始化 OSS 客户端
  const client = new OSS({
    region: "oss-cn-huhehaote",
    accessKeyId,
    accessKeySecret,
    stsToken,
    bucket,
    secure: true, // 设置为 true 启用 https
  });

  const erroredFiles: string[] = [];
  const savedFiles: string[] = [];

  await Promise.all(
    files.map(async ({ id, buffer }) => {
      try {
        // 上传文件到指定的 OSS 路径
        await client.put(
          `${prefix}/${id}`, 
          new Blob([buffer], 
          { type: 'application/octet-stream' },
        ));
        savedFiles.push(id);
      } catch (error) {
        console.error(`Failed to upload file ${id}:`, error);
        erroredFiles.push(id);
      }
    }),
  );

  return { savedFiles, erroredFiles };
};

export const loadFilesFromOss = async (
  prefix: string,
  decryptionKey: string,
  filesIds: readonly FileId[],
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();

  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        const url = `https://op-media.oss-cn-huhehaote.aliyuncs.com/${encodeURIComponent(
          prefix.replace(/^\//, ""),
        )}%2F${id}`;
        const response = await fetch(`${url}?alt=media`);
        if (response.status < 400) {
          const arrayBuffer = await response.arrayBuffer();

          const { data, metadata } = await decompressData<BinaryFileMetadata>(
            new Uint8Array(arrayBuffer),
            {
              decryptionKey,
            },
          );

          const dataURL = new TextDecoder().decode(data) as DataURL;

          loadedFiles.push({
            mimeType: metadata.mimeType || MIME_TYPES.binary,
            id,
            dataURL,
            created: metadata?.created || Date.now(),
            lastRetrieved: metadata?.created || Date.now(),
          });
        } else {
          erroredFiles.set(id, true);
        }
      } catch (error: any) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  return { loadedFiles, erroredFiles };
};
